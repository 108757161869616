import React from "react";
import { Layout, FloatButton } from "antd";
import Header from "./header";
import Footer from "./footer";
import Style from "./style.module.scss";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

function AppLayout(props) {
  const { children } = props;

  return (
    <Layout
      id="layout"
      className={classNames(Style.layout, "relative")}
      hasSider
    >
      <header className="relative z-50">
        <Header />
      </header>
      <main>
        <div className="sm:px-8 mt-6 sm:mt-24">
          <div className="mx-auto max-w-6xl lg:px-8">{children}</div>
        </div>
      </main>

      <footer className="mt-32">
        <Footer />
      </footer>

      <FloatButton.BackTop target={() => document.getElementById("layout")} />
    </Layout>
  );
}

export default inject("userStore")(observer(AppLayout));
