import userStore from "./user";
// import productStore from "./product";
// import commentStore from "./comment";
// import noteStore from "./note";
// import askStore from "./ask";
// import stypeStore from "./stype";

const config = {
  userStore,
  // productStore,
  // commentStore,
  // noteStore,
  // askStore,
  // stypeStore,
};

export default config;
