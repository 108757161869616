import Link from "next/link";
import { links } from "../nav";
import { Drawer, DrawerContent, DrawerTrigger } from "~/components/ui/drawer";
import { cn } from "~/lib/utils";
import { useState } from "react";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { withRouter } from "next/router";

const MobileNavs = () => {
  const [open, setOpen] = useState(false);

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <HamburgerMenuIcon className="md:hidden" />
      </DrawerTrigger>
      <DrawerContent>
        <ul>
          {links.map(({ href, label, target }) => (
            <li key={href} className="text-center py-2">
              <Link
                passHref
                href={href}
                target={target}
                className={cn(
                  "text-foreground transition-colors hover:text-foreground"
                )}
                onClick={() => setOpen(false)}
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </DrawerContent>
    </Drawer>
  );
};

const Tmp = withRouter(MobileNavs);

export default Tmp;
