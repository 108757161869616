// import { makeAutoObservable } from "mobx";

export default class Base {
  // token = '';
  // httpClient = null;
  constructor(initState = {}) {
    // makeAutoObservable(this);
    for (const k in initState) {
      if (Object.prototype.hasOwnProperty.call(initState, k)) {
        this[k] = initState[k];
      }
    }
  }
}

export function setInitState(initState = {}) {
  for (const k in initState) {
    if (Object.prototype.hasOwnProperty.call(initState, k)) {
      this[k] = initState[k];
    }
  }
}
