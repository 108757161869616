import "./global.css";
import "../common/style/vars.css";
import "../common/style/reset.scss";
import App from "next/app";
import Layout from "../components/layout";
import { initializeStore } from "../stores";
import { Provider, observer } from "mobx-react";
import { ConfigProvider, FloatButton, theme } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
// import "antd/dist/antd.css";
import Head from "next/head";
import { SITE_NAME, getHost } from "~/utils";
import Script from "next/script";
import Modals from "~/components/Modals";
import Theme from "./theme/themeConfig";

const withoutLayout = [
  "/tiny-code/[id]",
  "/tiny-code/embed/[id]",
  "/tiny-code/create",
  "/mind",
  "/github-rank",
];

const darkTemePages = [
  "/tiny-code/[id]",
  "/tiny-code/embed/[id]",
  "/tiny-code/create",
];

@observer
class MyApp extends App {
  mobxStore = {};
  static async getInitialProps(appContext) {
    const ctx = appContext.ctx;
    ctx.mobxStore = initializeStore();
    const appProps = await App.getInitialProps(appContext);

    return {
      ...appProps,
      initialMobxState: ctx.mobxStore,
    };
  }

  constructor(props) {
    super(props);
    const isServer = typeof window === "undefined";
    this.mobxStore = isServer
      ? props.initialMobxState
      : initializeStore(props.initialMobxState);
  }

  componentDidMount() {
    // this.initAds();
    this.mobxStore.userStore.getOperator();
  }

  initAds = () => {
    if (!window.adsbygoogle) {
      window.adsbygoogle = [];
    }
    if (!window.adsbygoogle.length) {
      window.adsbygoogle.push({});
    }
  };

  renderContent = () => {
    const { Component, pageProps } = this.props;

    const header = (
      <>
        <Head>
          <title key="title">{SITE_NAME}</title>
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,user-scalable=no,maximum-scale=1.0,viewport-fit=cover"
          />
          {/* // 添加canonical */}
          <link
            rel="canonical"
            href={getHost() + (this.props?.router?.asPath || "")}
          ></link>
        </Head>
      </>
    );
    if (withoutLayout.includes(this.props.router.route)) {
      return (
        <>
          {header}
          <Component {...pageProps} />
        </>
      );
    }

    return (
      <>
        <div className="fixed inset-0 bg-[url('/images/grid-black.svg')]"></div>
        <div className="fixed inset-0 flex justify-center sm:px-8">
          <div className="flex w-full max-w-7xl lg:px-8">
            <div className="w-full bg-zinc-50/90 ring-1 ring-zinc-100"></div>
          </div>
        </div>

        <Layout>
          {header}
          <Component {...pageProps} />
        </Layout>
      </>
    );
  };

  render() {
    const isDarkTheme = darkTemePages.includes(this.props.router.route);
    return (
      <Provider {...this.mobxStore}>
        <ConfigProvider
          locale={zhCN}
          theme={{
            ...Theme,
            algorithm: isDarkTheme ? theme.darkAlgorithm : undefined,
          }}
        >
          {this.renderContent()}
          <Modals />
          <FloatButton.BackTop />
        </ConfigProvider>
      </Provider>
    );
  }
}

export default MyApp;
