import { makeAutoObservable } from "mobx";
import {
  logout,
  auth,
  registry,
  checkUserName,
  checkUserEmail,
  updateUserAvatar,
  updateUser,
} from "../http";
import { FormatUser } from "../domain/user";
import localforage from "localforage";
import { graphql } from "~/http/graphql";
import { setInitState } from "./base";

export default class UserStore {
  // constructor(data={}) {
  // super(data);
  // this.token = data.token;
  // this.httpClient =
  // }

  currentUser = null;
  users = [];
  token = "";
  qiniuToken = "";
  userCount = 0;
  productCount = 0;
  commentCount = 0;
  settingType = "products";
  operator = null;

  constructor(initState = {}) {
    makeAutoObservable(this);
    setInitState.call(this, initState);
  }

  setCurrentUser(data) {
    this.currentUser = data;
  }
  setToken(data) {
    this.token = data || "";
  }
  setQiniuToken(data) {
    this.qiniuToken = data || "";
  }
  setAuthInfo(data) {
    this.token = data.token;
    this.qiniuToken = data.qiniuToken;
  }

  get uploadData() {
    return {
      token: this.qiniuToken,
    };
  }

  logout() {
    logout().then(() => {
      window.location.reload();
    });
  }

  auth(data) {
    return auth(data).then((data) => {
      if (data.success) {
        localforage.setItem("biz-token", data.data);
        window.location.reload();
      }
    });
  }

  async registry(data) {
    return await registry(data);
  }

  async checkUserName(name) {
    return await checkUserName({
      name,
    });
  }

  async checkUserEmail(email) {
    return await checkUserEmail({
      email,
    });
  }

  async updateUserAvatar(avatar) {
    const res = await updateUserAvatar({
      token: this.token,
      id: this.currentUser.id,
      avatar,
    });
    this.currentUser = FormatUser(res.data);
  }

  async updateUser(data) {
    const res = await updateUser({
      token: this.token,
      ...data,
    });
    this.currentUser = FormatUser(res.data);
  }

  async getOperator() {
    const tokenInfo = await localforage.getItem("biz-token");
    if (tokenInfo?.operatorId) {
      const { data, success } = await graphql({
        query: `
      {
        Operator(id:"${tokenInfo?.operatorId}") {
          id
          name
          avatar
          email
        }
      }
      `,
      });

      if (success) {
        this.operator = data;
      }
    }
  }
}
