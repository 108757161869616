import axios from "axios";
import HandleError from "./error";
import localforage from "localforage";

// export const baseURL = process.env.NODE_ENV === 'development' ? '/v1' : 'https://api.vwood.xyz/v1';
export const baseURL = "https://api.vwood.xyz/v1";
export const cdnURL =
  process.env.NODE_ENV === "development"
    ? "http://cdn.vwood.xyz"
    : "http://cdn.vwood.xyz";

export async function httpBase(options = {}) {
  const headers = {
    "content-type": "application/json",
  };
  const authInfo =
    typeof window !== "undefined"
      ? await localforage.getItem("biz-token")
      : undefined;

  if (authInfo) {
    headers.token = authInfo?.token;
    headers.tenantId = authInfo?.tenantId;
  }

  return axios.create({
    baseURL: options?.isNextApi ? undefined : (url || baseURL),
    timeout: 60000,
    headers,
  });
}

async function http(data, options) {
  const instance = await httpBase(options);

  instance.interceptors.response.use(
    function (response) {
      return response.data;
    },
    function (error) {
      HandleError(error.response);
      return Promise.reject(error);
    }
  );

  return instance(data);
}

export function localHttp(data) {
  const instance = httpBase();

  instance.interceptors.response.use(
    function (response) {
      return response.data;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return instance(data);
}

export default http;
