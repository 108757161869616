import React from "react";
import Navs from "./nav";
import { inject } from "mobx-react";
import Modals from "~/components/Modals";
import MobileNavs from "./mobileNavs";
import { SITE_NAME } from "~/utils";
import Login from "~/components/login";
import { observer } from "mobx-react-lite";

const Header = (props) => {
  // const handleMobileNavs = () => {
  //   Modals.show(MobileNavs);
  // };

  return (
    <div className="flex flex-col">
      <div className="sticky top-0 z-10 h-16 pt-6">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="relative px-4 sm:px-8 lg:px-12">
            <div className="mx-auto max-w-2xl lg:max-w-5xl">
              <div className="relative flex gap-4 items-center">
                <div className="flex-1">
                  <a href="/" className="inline-flex items-center">
                    <img
                      alt={SITE_NAME}
                      src="/images/logo-48.png"
                      className="w-[32px]"
                    />
                    <h2 className="sm:inline ml-2 md:hidden">{SITE_NAME}</h2>
                  </a>
                </div>
                <Navs />

                <div className="flex-1 flex justify-end">
                  {/* <span className="sm:hidden"> */}
                    {/* <MenuOutlined onClick={handleMobileNavs} /> */}
                    <MobileNavs />
                  {/* </span> */}

                  {/* {props.userStore.operator ? null : (
                    <span className="hidden sm:inline">
                      <Button
                        onClick={() => {
                          Modals.show(Login);
                        }}
                      >
                        登陆
                      </Button>
                    </span>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("userStore")(observer(Header));
