import React, { useState, useMemo } from "react";
import Dayjs from "dayjs";

import Link from "next/link";

const START_DATE = "2020-01-21";

const Footer = () => {
  const [wechat, setWechat] = useState(false);
  const days = useMemo(() => {
    return Dayjs().diff(Dayjs(START_DATE), "day");
  });

  return (
    <div className="sm:px-8 text-zinc-500/80 text-sm">
      <div
        className={
          "mx-auto max-w-7xl lg:px-8 border-t border-zinc-100 pb-12 pt-10"
        }
      >
        <div className="relative px-4 sm:px-8 lg:px-12">
          <Link
            href="https://beian.miit.gov.cn/"
            passHref
            target="_blank"
          >
            蜀ICP备19030923号-1
          </Link>
        </div>
        <div className="relative px-4 sm:px-8 lg:px-12 mt-2">
          Copyright © {new Date().getFullYear()} All Rights Reserved.
        </div>
        <div className="relative px-4 sm:px-8 lg:px-12 mt-2 flex items-center">
          本站由
          <a
            href="https://www.upyun.com/?utm_source=lianmeng&utm_medium=referral"
            target="_blank"
            title="upyun"
          >
            <img
              src="/images/又拍云_logo2.png"
              alt="upyun"
              className="h-[28px]"
            />
          </a>
          提供CDN加速/云存储服务
        </div>
      </div>
    </div>
  );
};

export default Footer;